import {
  Box,
  Button,
  Grid,
  Typography,
  // Tooltip,
  // TooltipProps,
  // styled,
  // tooltipClasses,
  Link as MuiLink,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
// import { InformationIcon } from "../../svgs";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useGetPlayListByIdQuery } from "../../components/apis/playlist";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../components/consts/routes.consts";
import GenresData from "../../components/modals/genres-data";
import DropDownPopup from "../../components/dropdown/dropdown-popup";
import { useDeletePlayListMutation } from "../../components/apis/playlist";
import { EditGatesDialog } from "../../components/modals/edit-gates-modal";
import { themeFonts } from "../../configs";
// import { useGetMeInfoQuery } from "../../components/apis/userDetailsAPi";
import { SpotifyGreenLogo } from "../../pngs";

// const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(() => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     marginTop: "0px !important",
//     marginBottom: "18px !important",
//     boxShadow: "10px 10px 20px 0px #0000001A",
//     border: "0.5px solid #00000033 !important",
//     background: "#FFFFFF",
//     color: "#000000",
//     textAlign: "center",
//     width: "267px",
//     borderRadius: "0px",
//     padding: "10px 10px 16px 10px",
//   },
//   [`& .${tooltipClasses.arrow}`]: {
//     color: "#FFFFFF",
//     fontSize: "30px",
//   },
// }));

export const YourPlaylistPage = () => {
  const isMiniScreen = useMediaQuery("(max-width:500px)");
  const isMidiScreen = useMediaQuery("(max-width:768px)");
  const isMediumScreen = useMediaQuery("(max-width:1014px)");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [platformType, setPlatformType] = useState("Spotify");
  const [spotifyAccountLink, setSpotifyAccountLink] = useState("");
  const [spotifyPlaylistLink, setSpotifyPlaylistLink] = useState("");
  const [spotifySongLink, setSpotifySongLink] = useState("");
  const [deletingItemId, setDeletingItemId] = useState<string | null>(null);
  const [deleteItem, { isLoading }] = useDeletePlayListMutation();
  const [isRefetching, setIsRefetching] = useState(false);
  const userId = localStorage.getItem("userId");
  const { data, refetch } = useGetPlayListByIdQuery({ userId });
  const handleDelete = async (id: string) => {
    try {
      setDeletingItemId(id);

      await deleteItem({
        id,
        isActive: false,
      }).unwrap();
      setIsRefetching(true);
      await refetch();
    } catch (error: any) {
    } finally {
      setDeletingItemId(null);
      setIsRefetching(false);
    }
  };

  const [playlistItemId, setPlaylistItemId] = useState<string | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setAnchorEl(event.currentTarget);
    setPlaylistItemId(id);
  };

  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
    setPlaylistItemId(null);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [isEditGatesOpen, setIsEditGatesOpen] = useState(false);
  const [editedData, setEditedData] = useState<any[]>([]);

  // const id = localStorage.getItem("id");

  const [selectedGenres, setSelectedGenres] = useState<any[]>([]);

  // const { data: userInfo } = useGetMeInfoQuery<any>({ id });

  const userName = localStorage.getItem("userName");
  const [editGenresId, setEditGenresId] = useState<string | null>(null);
  const handleClickEditOpen = (playlistId: string, data: any) => {
    setIsOpen(true);
    setEditedData([...data]);
    setEditGenresId(playlistId);
  };
  const handleClickEditClose = () => {
    setIsOpen(false);
  };
  const [editedGatesData, setEditedGatesData] = useState<any>({});
  const [editGatesId, setEditGatesId] = useState<string | null>(null);
  const handleClickEditGatesOpen = (playlistId: string, data: any) => {
    setIsEditGatesOpen(true);
    setEditGatesId(playlistId);
    setEditedGatesData({ ...data });
  };
  const handleClickEditGatesClose = () => {
    setIsEditGatesOpen(false);
    setEditGatesId(null);
    setEditedGatesData(null);
  };
  // const responseRateString = userInfo?.responseRate?.$numberDecimal ?? "0";
  // const responseRateDecimal = parseFloat(responseRateString);
  // const responseRatePercentage = (responseRateDecimal * 100).toFixed(2);

  const truncateString = (str: any, num: any) => {
    if (str?.length <= num) {
      return str;
    }
    return str?.slice(0, num) + "...";
  };
  return (
    <Grid
      container
      spacing={3}
      sx={{
        margin: "0px !important",
        flexDirection: "column",
        width: "100% !important",
        padding: "0 !important ",
        alignItems: isMediumScreen ? "center" : "none",
      }}
    >
      <Grid
        item
        xs={11}
        sx={{
          width: "100%",
          maxWidth:"100%",
          // padding: "0!important",
          display: "flex",
          flexDirection: "column",
          alignItems: isMiniScreen ? "center" : "unset",
          padding: isMiniScreen ? "0!important" :"32px 24px !important",
        }}
      >
        <Box
          className="flex-direction-column-sm"
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: isMidiScreen ? "center" : "center",
          }}
        >
          <Typography
            className="font-23-sm"
            sx={{
              fontSize: "32px",
              fontWeight: "600",
              color: "#000",
            }}
          >
            Hello, {userName}
          </Typography>

          <Button
            className="margin-0 width-fit-content"
            onClick={() => {
              navigate(ROUTES.SUBMIT_A_PLAYLIST);
            }}
            variant="contained"
            sx={{
              marginLeft: "auto",
              padding: "11px 20px",
              backgroundColor: "#00ADB5",
              color: "#fff",
              borderRadius: "33px",
              height: "auto",
              "&:hover": {
                background: " #00ADB5",
              },
            }}
          >
            Add new playlist
          </Button>
        </Box>
        {/* {data && data?.userPlaylist?.length !== 0 && (
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              marginTop: "32px",
              flexWrap: "wrap",
              justifyContent: isMiniScreen ? "center" : "unset",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#00ADB5",
                padding: "11px 16px",
                border: "1px solid #00ADB5",
                borderRadius: "5px",
              }}
            >
              Your Response Rate: {responseRatePercentage}%
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#000000",
                  fontSize: "11px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                Last 30 days
              </Typography>
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#00ADB5",
                padding: "11px 16px",
                border: "1px solid #00ADB5",
                borderRadius: "5px",
              }}
            >
              <Box sx={{ display: "flex ", gap: "4px", alignItems: "center" }}>
                Bonus Points: {userInfo?.bouncePoint}
                <HtmlTooltip
                  title={
                    <Typography
                      sx={{
                        margin: "0px",
                        fontFamily: themeFonts["Poppins-Medium"],
                        fontSize: "13px",
                        color: "#000000",
                        lineHeight: "19.5px",
                        textTransform: "capitalize",
                      }}
                    >
                      When the curator you invite adds at least one playlist to
                      our website and provides feedback on the first song from
                      their dashboard, you'll earn bonus points, making you more
                      active and climbing to a higher position on our top chart!
                    </Typography>
                  }
                  placement="top"
                  arrow
                >
                  <Typography
                    style={{
                      padding: "0px",
                      margin: "0px",
                      lineHeight: "23px",
                      marginLeft: "3px",
                    }}
                  >
                    <InformationIcon />
                  </Typography>
                </HtmlTooltip>
              </Box>

              <Typography
                sx={{
                  textAlign: "center",
                  color: "#000000",
                  fontSize: "11px",
                  fontFamily: themeFonts["Poppins-Regular"],
                }}
              >
                Last 30 days
              </Typography>
            </Typography>
          </Box>
        )} */}
        {data && data?.userPlaylist?.length === 0 ? (
          <Box
            sx={{
              backgroundColor: "#F0F0F0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "30px 0",
              textAlign: "center",
              height: "330px",
              padding:"15px",
              minWidth:"100%"
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "32px",
                  fontWeight: "600",
                  color: "#000000",
                }}
              >
                0 Playlists
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#00000080",
                }}
              >
                You Haven't submitted any playlists yet
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              marginTop: "32px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {data?.userPlaylist?.map((playlistItem: any, index: any) => {
              return (
                <>
                  <Box
                    key={index}
                    sx={{
                      boxShadow: "4px 4px 4px 0px #0000001A",
                      padding: "16px",
                      marginBottom: "32px",
                      display: "flex",
                      gap: isMiniScreen ? "0px" : "16px",
                      flexDirection: isMiniScreen ? "column" : "unset",
                      borderLeft: "1px solid #0000001A",
                      borderTop: "1px solid #0000001A",
                      backgroundColor:
                        deletingItemId === playlistItem._id &&
                        (isLoading || isRefetching)
                          ? "#B0B0B0"
                          : "#FFFFFF",
                      opacity:
                        deletingItemId === playlistItem._id &&
                        (isLoading || isRefetching)
                          ? 0.5
                          : 1,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: isMiniScreen ? "100%" : "144px",
                        height: isMiniScreen ? "auto" : "144px",
                        aspectRatio: "1 / 1",
                      }}
                    >
                      <Link
                        to={playlistItem?.playlistUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={playlistItem.imageUrl}
                          alt=""
                          className="img-fluid"
                          style={{ aspectRatio: "1 / 1" }}
                        />
                      </Link>
                    </Box>
                    <Box
                      sx={{
                        flex: "1",
                      }}
                    >
                      <Box
                        sx={{
                          height: "54px",
                          width: "117px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Link
                          to={"https://open.spotify.com/"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={SpotifyGreenLogo}
                            alt=""
                            style={{
                              width: "90px",
                              height: "27px",
                            }}
                          />
                        </Link>
                      </Box>
                      <Box
                        sx={{
                          paddingLeft: "13.5px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <MuiLink
                              component={Link}
                              to={playlistItem?.playlistUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{
                                fontSize: "16px",
                                color: "#000000",
                                fontFamily: themeFonts["Poppins-SemiBold"],
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                wordWrap: "break-word",
                                textDecoration: "none",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                            >
                              {isMiniScreen
                                ? truncateString(playlistItem.playlistName, 25)
                                : playlistItem.playlistName}
                            </MuiLink>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#00ADB5",
                                textTransform: "capitalize",
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textDecoration: "none",
                                marginRight: "-5px",
                              }}
                            >
                              (By
                            </Typography>
                            <MuiLink
                              component={Link}
                              to={playlistItem?.userId?.profileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#00ADB5",
                                textTransform: "capitalize",
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textDecoration: "none",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                            >
                              {playlistItem.ownerName}
                            </MuiLink>
                            <Typography
                              sx={{
                                fontSize: "10px",
                                fontWeight: "500",
                                color: "#00ADB5",
                                textTransform: "capitalize",
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textDecoration: "none",
                                marginLeft: "-7px",
                              }}
                            >
                              )
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              marginLeft: "auto",
                            }}
                          >
                            <Button
                              className="need-help-btn"
                              variant="contained"
                              onClick={(e) => handleClick(e, playlistItem._id)}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#000000;",
                                marginTop: "0px",
                                textAlign: "end  !important",
                                cursor: "pointer",
                                background: "transparent",
                                padding: "0px",
                                height: "25px",
                                display: "flex",
                                marginLeft: "auto",
                              }}
                            >
                              <MoreHorizIcon
                                sx={{
                                  fontSize: "35px",
                                }}
                              />
                            </Button>
                            <DropDownPopup
                              open={playlistItemId === playlistItem._id}
                              handleClose={handleClose}
                              playlistItemid={playlistItemId}
                              anchorEl={anchorEl}
                              handleClickEditOpen={handleClickEditOpen}
                              handleClickEditGatesOpen={
                                handleClickEditGatesOpen
                              }
                              playlistGenres={playlistItem.genres}
                              playlistGates={playlistItem.submitConditions}
                              handleDelete={handleDelete}
                            />
                            <GenresData
                              handleCloseData={handleClickEditClose}
                              isOpen={
                                isOpen && editGenresId === playlistItem._id
                              }
                              setSelectedGenres={setSelectedGenres}
                              selectedGenres={selectedGenres}
                              editedData={editedData}
                              refetch={refetch}
                              playlistId={playlistItem._id}
                            />
                            <EditGatesDialog
                              handleCloseData={handleClickEditGatesClose}
                              isOpen={
                                isEditGatesOpen &&
                                editGatesId === playlistItem._id
                              }
                              editedData={editedGatesData}
                              refetch={refetch}
                              playlistId={playlistItem._id}
                              setPlatformType={setPlatformType}
                              setSpotifyAccountLink={setSpotifyAccountLink}
                              setSpotifyPlaylistLink={setSpotifyPlaylistLink}
                              setSpotifySongLink={setSpotifySongLink}
                              platformType={platformType}
                              spotifyAccountLink={spotifyAccountLink}
                              spotifyPlaylistLink={spotifyPlaylistLink}
                              spotifySongLink={spotifySongLink}
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            marginTop: "5px",
                            gap: "8px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: "400",
                              color: "#00000080",
                            }}
                          >
                            Followers :{" "}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: "400",
                              color: "#00ADB5",
                            }}
                          >
                            {playlistItem.totalFollowers}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            marginTop: "5px",
                            gap: "8px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: "400",
                              color: "#00000080",
                            }}
                          >
                            Submissions :{" "}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: "400",
                              color: "#00ADB5",
                            }}
                          >
                            {playlistItem.totalSubmissions}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            marginTop: "5px",
                            gap: "8px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                fontWeight: "400",
                                color: "#00000080",
                              }}
                            >
                              # of Tracks :{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "10px",
                                fontWeight: "400",
                                color: "#00ADB5",
                              }}
                            >
                              {playlistItem.totalTracks}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {deletingItemId === playlistItem._id &&
                      (isLoading || isRefetching) && (
                        <Box
                          sx={{
                            position: "absolute",
                            right: "50%",
                            top: "35%",
                          }}
                        >
                          <CircularProgress
                            size={40}
                            sx={{
                              color: "inherit",
                              fontWeight: "900",
                            }}
                          />
                        </Box>
                      )}
                  </Box>
                </>
              );
            })}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
