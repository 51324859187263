import {
  Box,
  FormGroup,
  Grid,
  Stack,
  styled,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { LogBackground } from "../../pngs";
import { HomePageSection } from "./home-page-section";
// import { themeFonts } from "../../configs";
// import { AwardIcon } from "../../svgs";
import { PopularPlayList } from "./popular-playlist";
// import { Features } from "./features";
import { PointOfViewSection } from "./point-of-views/point-of-views-section";
import { Footer } from "../footer";
import CookieConsent from "react-cookie-consent";
import { useState, useEffect } from "react";

export const Home = () => {
  const xxs = useMediaQuery("(max-width:745px)");
  const Accepted = localStorage.getItem("cookies_accepted");
  const [cookiesAccepted, setCookiesAccepted] = useState<any>();
  console.log(cookiesAccepted, "cookiesAccepted");

  const handleAccept = () => {
    const accepted = window.localStorage.setItem(
      "cookies_accepted",
      "Accepted"
    );
    setCookiesAccepted(accepted);
    // Perform actions for accepting cookies, e.g., enabling tracking
  };
  useEffect(() => {
    // Safely check for cookies on mount
    const storedValue = localStorage.getItem("cookies_accepted");
    setCookiesAccepted(storedValue);
  }, []);
  const handleDecline = () => {
    // Perform actions for declining cookies, e.g., disabling tracking
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 24,
    height: 14,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(8px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      top: 0,
      left: "1px",
      "&.Mui-checked": {
        transform: "translateX(8px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "White" : "#0BD67E",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 10,
      height: 10,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          margin: "0px !important",
          flexDirection: "column",
          width: "100% !important",
        }}
      >
        <Box
          sx={{
            background: `url(${LogBackground})`,
            backgroundSize: "cover",
            minHeight: xxs ? "auto" : "660px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            marginTop: "-80px",
          }}
        >
          <Box
            sx={{
              paddingTop: "80px",
            }}
          >
            <HomePageSection />
          </Box>
        </Box>
        {/* <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            alignItems: "center",
            padding: "24px !important",
          }}
        >
          <Chip
            label="100k+ Artists & Curators Trust PitchPlaylists"
            sx={{
              color: "#FFFFFF",
              fontSize: "18px",
              fontFamily: themeFonts["Poppins-Medium"],
              background: `url(${ChipImg})`,
              height: "35px",
              paddingX: "8px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
          <Typography
            sx={{
              color: "#000000",
              fontSize: "32px",
              fontFamily: themeFonts["Poppins-SemiBold"],
              marginTop: "16px",
              textAlign: "center",
            }}
          >
            Weekly update on the leading curator
          </Typography>
          <Box
            sx={{
              marginTop: "32px",
            }}
          >
            <AwardIcon />
          </Box>
          <Typography
            sx={{
              color: "#00000099",
              fontSize: "16px",
              fontFamily: themeFonts["Poppins-Regular"],
              marginTop: "16px",
              maxWidth: 580,
              textAlign: "center",
              marginBottom: "80px",
            }}
          >
            Discover the most active curators to guide your music submissions!
          </Typography>
        </Grid> */}
        <PopularPlayList />
        {/* <Features /> */}
        <PointOfViewSection />
        <Footer />

        <CookieConsent
          location="bottom"
          buttonText="I agree"
          visible={Accepted !== "Accepted" ? "show" : "hidden"}
          enableDeclineButton
          declineButtonText={
            <Typography
              sx={{
                fontSize: "14px",
                color: "#000000",
                fontFamily: "Poppins-Medium",
                display: "flex",
                gap: "9px",
                textTransform: "capitalize",
              }}
            >
              Cookies enabled
              <FormGroup sx={{ justifyContent: "center" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    gap: "5px",
                  }}
                >
                  <AntSwitch
                    // checked={checked}
                    // onChange={handleSwitchChange}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </Stack>
              </FormGroup>
            </Typography>
          }
          onAccept={handleAccept}
          onDecline={handleDecline}
          cookieName="yourCookieConsent"
          buttonWrapperClasses="cookie-buttons"
          contentClasses="cookie-content"
          style={{
            background: "#BEEAEB",
            color: "rgb(0 0 0)",
            flexDirection: "column",
            width: "100%",
            maxWidth:"550px",
            right: 0,
            left: "none",
            padding: "15px",
            borderStartStartRadius: "14px",
          }}
          buttonStyle={{
            background:
              "linear-gradient(90deg, rgba(33,40,49,1) 0%, rgba(31,49,58,1) 35%, rgba(24,77,86,1) 66%, rgba(23,84,93,1) 100%)",
            color: "#ffffff",
            fontSize: "13px",
            border: "none",
            borderRadius: "40px",
            padding: "7px 24px",
            margin: "0",
          }}
          declineButtonStyle={{
            background: "none",
            color: "rgb(122 117 117 / 75%)",
            fontSize: "13px",
            border: "none",
            borderRadius: "4px",
            margin: 0,
            display: "none",
          }}
        >
          By continuing to use our Website, you consent to our use of cookies in
          accordance with this {" "}
          <a href="https://pitchplaylists.com/terms-and-conditions">
            Cookie Policy
          </a>
          . If you do not agree with our use of cookies, you should either
          adjust your browser settings or refrain from using our Website.
        </CookieConsent>
      </Grid>
    </>
  );
};
