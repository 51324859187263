import {
  Box,
  Button,
  Checkbox,
  // Chip,
  Divider,
  // FormGroup,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
  Grid,
  // Stack,
  // Switch,
  TextField,
  Typography,
  useMediaQuery,
  FormControl,
  SelectChangeEvent,
  Link as MuiLink,
  IconButton,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React, { useEffect, useState } from "react";
import { themeColors, themeFonts } from "../configs";
import { SpotifyWhiteLogo } from "../pngs";
import { Footer } from "./footer";
import { CloseIconBlue, InformationIconGr, SortArrow } from "../svgs";
import CloseIcon from "@mui/icons-material/Close";
// import { FiberManualRecord } from "@mui/icons-material";
import { CustomCheckbox } from "../components/checkbox";
import { useGetGenresListQuery } from "../components/apis/genresData";
import SelectMoreSubmissions from "../components/modals/want-more-submissions";
import {
  useGetPlayListByIdQuery,
  useGetPlayListQuery,
} from "../components/apis/playlist";
import { toast } from "react-toastify";
import {
  useCreateTrackMutation,
  useGetTrackByIdQuery,
} from "../components/apis/artistsApi";
import { ROUTES } from "../components/consts/routes.consts";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../components/modals/genres-data";
import { MenuItem, Select } from "@mui/material";
import { SongSubmissionDialog } from "../components/modals/song-submission-modal";
import { useGetUsersListQuery } from "../components/apis/userDetailsAPi";
import { format } from "date-fns";
// import { format } from "date-fns";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: "0px !important",
    marginBottom: "18px !important",
    boxShadow: "10px 10px 20px 0px #0000001A",
    border: "0.5px solid #00000033 !important",
    background: "#FFFFFF",
    color: "#000000",
    textAlign: "center",
    padding: "10px 10px 16px 10px",
    width: "auto",
    borderRadius: "0px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFFFFF",
    fontSize: "30px",
  },
}));

// const AntSwitch = styled(Switch)(({ theme }) => ({
//   width: 24,
//   height: 14,
//   padding: 0,
//   display: "flex",
//   "&:active": {
//     "& .MuiSwitch-thumb": {
//       width: 15,
//     },
//     "& .MuiSwitch-switchBase.Mui-checked": {
//       transform: "translateX(8px)",
//     },
//   },
//   "& .MuiSwitch-switchBase": {
//     padding: 2,
//     top: 0,
//     left: "1px",
//     "&.Mui-checked": {
//       transform: "translateX(8px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         opacity: 1,
//         backgroundColor: theme.palette.mode === "dark" ? "White" : "#0BD67E",
//       },
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
//     width: 10,
//     height: 10,
//     borderRadius: 6,
//     transition: theme.transitions.create(["width"], {
//       duration: 200,
//     }),
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor:
//       theme.palette.mode === "dark"
//         ? "rgba(255,255,255,.35)"
//         : "rgba(0,0,0,.25)",
//     boxSizing: "border-box",
//   },
// }));

function groupBy(array: any[], key: string) {
  return array.reduce((result, item) => {
    const groupKey = item[key];

    if (!result[groupKey]) {
      result[groupKey] = [];
    }

    result[groupKey].push(item);
    return result;
  }, {});
}
export const SelectingGenres = () => {
  const [sortBy, setSortBy] = useState<string>("curatorActivity");

  const handleStateChange = (event: SelectChangeEvent<string>) => {
    setSortBy(event.target.value);
    setIsRendered(true);
  };
  function getNextMonday() {
    let today = new Date();
    let day = today.getDay();

    let daysUntilMonday = 1 - day;

    if (daysUntilMonday <= 0) {
      daysUntilMonday += 7;
    } else if (daysUntilMonday === 0) {
      daysUntilMonday += 7;
    }

    let nextMonday = new Date(today);
    nextMonday.setDate(today.getDate() + daysUntilMonday);

    return nextMonday;
  }
  let nextMonday = getNextMonday();
  const [open, setOpen] = useState(false);
  const [isRendered, setIsRendered] = useState(true);
  const [hasOpen, setHasOpen] = useState(false);
  const location = useLocation();
  const { trackData, curatorName } = location.state || {};
  const { data: genres } = useGetGenresListQuery();
  const [searchValue, setSearchValue] = useState("");
  const [searchCountry, setSearchCountry] = useState("");
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [selectedGenres, setSelectedGenres] = useState<any[]>([]);
  const [filteredPlaylists, setFilteredPlaylists] = useState<any[]>([]);
  const [lastAttemptedSelection, setLastAttemptedSelection] = useState(null);
  // const [checked, setChecked] = useState(false);
  // const handleSwitchChange = (event: any) => {
  //   setChecked(event.target.checked);
  //   setIsRendered(true);
  // };
  const navigate = useNavigate();
  const [submitTrack, { isLoading }] = useCreateTrackMutation();
  const { data: playlist, refetch: playlistRefetch } =
    useGetPlayListQuery<any>();
  const isLaptopScreen = useMediaQuery("(max-width:1775px)");
  const isMediumScreen = useMediaQuery("(max-width:1360px)");
  const isLargeScreen = useMediaQuery("(max-width:1800px)");
  const isExtraLargeScreen = useMediaQuery("(min-width:1921px)");
  const isTabletScreen = useMediaQuery("(max-width:1000px)");
  const isSmallScreen = useMediaQuery("(max-width:810px)");
  const isExtraSmallScreen = useMediaQuery("(max-width:623px)");
  const { data: creditsData, refetch: creditsRefetch } =
    useGetUsersListQuery<any>();

  const [isOpen, setIsOpen] = useState(false);
  const [filterOptionOpen, setFilterOptionOpen] = useState(false);
  const handleSelectGenresOpen = () => {
    setIsOpen((prev) => !prev);
  };
  const handleFilterOptionOpen = () => {
    setFilterOptionOpen((prev) => !prev);
  };
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const handleClose = () => {
    setOpen(false);
  };
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleCloseButton = () => {
    setHasOpen(false);
    setSelectedItems((prevSelectedItems: { playlistIds: any[] }) => {
      return {
        ...prevSelectedItems,
        playlistIds: prevSelectedItems.playlistIds.filter(
          (id: null) => id !== lastAttemptedSelection
        ),
      };
    });
    setLastAttemptedSelection(null);
  };
  // const handleOpenButton = () => {
  //   setHasOpen(true);
  // };
  const sortedGenres = genres
    ? [...genres].sort((a: any, b: any) => {
        return a.categoryIndex - b.categoryIndex;
      })
    : [];

  const groupedData = sortedGenres ? groupBy(sortedGenres, "category") : {};

  const [selectedItems, setSelectedItems] = useState<any>({
    playlistIds: [],
  });

  const handleRemoveGenre = (genreToRemove: any) => {
    setSelectedGenres((prevSelected: any[]) =>
      prevSelected.filter((genre: { id: any }) => genre.id !== genreToRemove.id)
    );
  };

  const [selectedPlaylists, setSelectedPlaylists] = useState([]);
  const handleCheckboxChange = (genre: any) => {
    setSelectedGenres((prevSelected: any[]) => {
      const newSelected = prevSelected.some(
        (selected: { id: any }) => selected.id === genre.id
      )
        ? prevSelected.filter(
            (selected: { id: any }) => selected.id !== genre.id
          )
        : [...prevSelected, genre];
      return newSelected;
    });
  };

  const isChecked = (genre: any) => {
    return selectedGenres.some(
      (selected: { id: any }) => selected.id === genre.id
    );
  };
  const id = localStorage.getItem("userId");
  const { refetch } = useGetPlayListByIdQuery({
    userId: id,
  });
  const { refetch: trackRefetch } = useGetTrackByIdQuery<any>({
    userId: id,
  });
  const userCredits = creditsData?.user?.userCredits ?? 0;
  // const playlistCount = selectedItems?.playlistIds?.length ?? 0;
  // const remainingSubmissions = Math.max(userCredits - playlistCount, 0);

  const filteredPlaylistsAlready = playlist?.map((track: any) => {
    const uniqueOwners = new Set();
    playlist.forEach((pl: any) => {
      pl.submittedTracks.forEach((submittedTrack: any) => {
        if (submittedTrack.track.trackId === trackData) {
          uniqueOwners.add(submittedTrack.spotifyId);
        }
      });
    });
    const isThreeOwnerLimitReached = uniqueOwners.size >= 3;
    const currentTrackOwnerIsAllowed = uniqueOwners.has(id);

    return {
      ...track,
      isAlreadyAdded: track?.submittedTracks?.some(
        (submittedTrack: any) => submittedTrack.track.trackId === trackData
      ),
      isOwnPlaylist: track?.playlistOwnerId === id,
      isDisabled:
        isThreeOwnerLimitReached &&
        !currentTrackOwnerIsAllowed &&
        !uniqueOwners.has(id),
    };
  });
  const extractPlaylistIdFromUrl = (url: string) => {
    const parts = url.split("/");
    const lastPart = parts[parts.length - 1];
    const [playlistId] = lastPart.split("?");
    return playlistId;
  };

  const lowerSearchValue = searchValue.toLowerCase();
  let searchId = lowerSearchValue;
  if (searchValue.startsWith("https://open.spotify.com/playlist/")) {
    searchId = extractPlaylistIdFromUrl(searchValue).toLowerCase();
  }
  const filteredPlaylist =
    filteredPlaylistsAlready &&
    filteredPlaylistsAlready
      ?.filter((track: any) => {
        const lowerSearchValue = searchValue.toLowerCase();
        const nameMatches = track?.playlistName
          ?.toLowerCase()
          .includes(lowerSearchValue);
        const urlIdMatches = track?.playlistId
          ?.toLowerCase()
          .includes(searchId);
        const curatorMatches = track?.ownerName
          ?.toLowerCase()
          .includes(lowerSearchValue);
        return nameMatches || urlIdMatches || curatorMatches;
      })
      .sort((a: any, b: any) => {
        const activityOrder: any = {
          Active: 0,
          "Active Recently": 1,
          "Not Active Recently": 2,
        };
        switch (sortBy) {
          case "followersLowToHigh":
            return a.totalFollowers - b.totalFollowers;
          case "followersHighToLow":
            return b.totalFollowers - a.totalFollowers;
          case "trackLowToHigh":
            return a.totalTracks - b.totalTracks;
          case "trackHighToLow":
            return b.totalTracks - a.totalTracks;
          case "curatorActivity":
            return (
              activityOrder[a.userId?.currentStatus] -
              activityOrder[b.userId?.currentStatus]
            );
          default:
            return 0;
        }
      });
  const finalFilteredPlaylist = filteredPlaylist?.filter((track: any) => {
    const lowerCountryValue = searchCountry.toLowerCase();
    const countryMatches =
      track?.country?.toLowerCase().includes(lowerCountryValue) ||
      track?.userId?.country?.toLowerCase().includes(lowerCountryValue);
    // const isPromotedMatches = checked ? track?.promoted === true : true;
    return countryMatches;
    // && isPromotedMatches;
  });

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
    setIsRendered(true);
  };
  const handleSearchByCountries = (event: any) => {
    setSearchCountry(event.target.value);
    setIsRendered(true);
  };

  useEffect(() => {
    if (selectedGenres.length === 0) {
      if (isRendered) {
        setFilteredPlaylists(finalFilteredPlaylist);
        setIsRendered(false);
      }
    } else {
      const filtered = finalFilteredPlaylist?.filter((playlistItem: any) =>
        selectedGenres.every((selectedGenre) =>
          playlistItem.genres?.some(
            (playlistGenre: any) => playlistGenre.name === selectedGenre.name
          )
        )
      );
      if (isRendered) {
        setFilteredPlaylists(filtered);
        setIsRendered(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalFilteredPlaylist, selectedGenres]);

  useEffect(() => {
    if (curatorName) {
      setSearchValue(curatorName);
    }
  }, [curatorName]);

  useEffect(() => {
    setIsRendered(true);
  }, [playlist, selectedGenres]);

  const handleSubmit = async () => {
    try {
      const res = await submitTrack({
        trackId: trackData,
        playlistIds: selectedItems.playlistIds,
        id: id,
      }).unwrap();
      toast.success("Success");
      if (res) {
        navigate(ROUTES.DASHBOARD);
      }
    } catch (error: any) {
      toast.error("Failed");
    }
    creditsRefetch();
    playlistRefetch();
    refetch();
    trackRefetch();
  };
  const handlePropagation = (event: any) => {
    event.stopPropagation();
  };
  const handlePlaylistCheckboxChange = (
    playlistIds: any,
    playlistItem: any
  ) => {
    setSelectedItems((prevSelectedItems: any) => {
      const playlistExists =
        prevSelectedItems.playlistIds.includes(playlistIds);
      const newPlaylistIds = playlistExists
        ? prevSelectedItems.playlistIds.filter((id: any) => id !== playlistIds)
        : [...prevSelectedItems.playlistIds, playlistIds];
      const newPlaylistCount = newPlaylistIds.length;
      if (userCredits - newPlaylistCount < 0) {
        setHasOpen(true);
        setLastAttemptedSelection(playlistIds);
        return prevSelectedItems;
      }
      return {
        ...prevSelectedItems,
        playlistIds: newPlaylistIds,
      };
    });
    setSelectedPlaylists((prevSelectedPlaylists: any) => {
      const playlistExists = prevSelectedPlaylists.some(
        (p: any) => p._id === playlistItem._id
      );
      const newSelectedPlaylists = playlistExists
        ? prevSelectedPlaylists.filter((p: any) => p._id !== playlistItem._id)
        : [...prevSelectedPlaylists, playlistItem];

      const newPlaylistCount = newSelectedPlaylists.length;
      if (userCredits - newPlaylistCount < 0) {
        setHasOpen(true);
        setLastAttemptedSelection(playlistItem._id);
        return prevSelectedPlaylists;
      }

      return newSelectedPlaylists;
    });
  };
  const isPlaylistChecked = (playlistId: any) => {
    return selectedItems.playlistIds.includes(playlistId);
  };

  const truncateString = (str: any, num: any) => {
    if (str?.length <= num) {
      return str;
    }
    return str?.slice(0, num) + "...";
  };

  const handleBoxClick = (playlistId: any, playlistItem: any) => {
    if (
      !playlistItem.isAlreadyAdded &&
      !playlistItem.isOwnPlaylist &&
      !playlistItem.isDisabled
    ) {
      handlePlaylistCheckboxChange(playlistId, playlistItem);
    }
  };
  const selectingGenres = "selectingGenres";
  return (
    <Grid
      container
      spacing={3}
      sx={{
        display: "flex",
        marginTop: "0px !important",
        justifyContent: isExtraSmallScreen ? "center" : "flex-start",
        width: "100%",
        marginLeft: "0 !important",
      }}
    >
      {isExtraSmallScreen && !isOpen && (
        <Box
          sx={{
            width: "100%",
            paddingX: "8px",
            display: "flex",
            alignItems: isOpen ? "flex-end" : "flex-start",
            background: themeColors["#FFFFFF"],
            zIndex: 999,
            position: "fixed",
            top: "80px",
            flexDirection: "column",
            paddingTop: "16px",
          }}
        >
          <Button
            onClick={handleSelectGenresOpen}
            sx={{
              fontFamily: "Poppins-Regular",
              fontWeight: "600",
              fontSize: "22px",
              lineHeight: "22px",
              color: "#00ADB5 !important",
              minWidth: "0px",
              height: "auto",
              background: "#FFFFFF !important",
              borderRadius: "18px",
              "& span.MuiButton-icon": {
                display: "block",
                margin: "0px",
                lineHeight: "12px",
                "& svg": {
                  height: "31px",
                  width: "31px",
                },
              },
            }}
            endIcon={<ChevronRightIcon />}
          >
            {isOpen ? "Save Genres" : "Select Genres"}
          </Button>{" "}
          <Button
            onClick={handleFilterOptionOpen}
            sx={{
              fontFamily: "Poppins-Regular",
              fontWeight: "600",
              fontSize: "22px",
              lineHeight: "22px",
              color: "#00ADB5",
              height: "auto",
              "& span.MuiButton-icon": {
                transform: filterOptionOpen ? "rotate(92deg)" : "rotate(0deg)",
                margin: "0px",
                lineHeight: "12px",
                "& svg": {
                  height: "31px",
                  width: "31px",
                },
              },
            }}
            endIcon={<ChevronRightIcon />}
          >
            Filter Options
          </Button>
        </Box>
      )}
      <Grid item xs={12} sx={{ display: "flex", padding: "0 !important" }}>
        <Grid
          item
          xs={
            isExtraSmallScreen
              ? 12
              : isSmallScreen
              ? 4.5
              : isMediumScreen || isLargeScreen || isExtraLargeScreen
              ? 3.5
              : 2.5
          }
          sx={{
            display: isOpen ? "flex" : isExtraSmallScreen ? "none" : "flex",
            justifyContent: "end",
            flexWrap: "wrap",
            flexDirection: "row",
            // borderRight: isOpen ? "0 !important" : "1px solid #00000040",
            position: isOpen ? "fixed" : "inherit",
            width: isOpen ? "100%" : "auto",
            background: isOpen ? "white" : "none",
            zIndex: isOpen ? "999" : "0",
            top: isOpen ? "142px" : "none",
            height: isOpen ? "calc(100vh - 141px)" : "100vh",
            overflow: "scroll",
            padding: "0px !important",
          }}
        >
          <Box
            sx={{
              width: isOpen ? "100%" : "285px",
              padding: "32px",
              paddingTop: isOpen ? "0 !important" : "32px",
            }}
          >
            <Typography
              sx={{
                fontSize: "22px",
                fontFamily: themeFonts["Poppins-SemiBold"],
                color: themeColors["#00ADB5"],
                marginTop: "0px",
                lineHeight: "27px",
                display: isOpen ? "none" : "block",
              }}
            >
              Select Genres
            </Typography>
            {isExtraSmallScreen && isOpen && (
              <Box
                sx={{
                  width: "100%",
                  paddingX: "8px",
                  display: "flex",
                  alignItems: isOpen ? "flex-end" : "flex-start",
                  background: themeColors["#FFFFFF"],
                  zIndex: 999,
                  position: "fixed",
                  top: "80px",
                  flexDirection: isOpen ? "row" : "column",
                  paddingY: "16px",
                  left: 0,
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={handleSelectGenresOpen}
                  sx={{
                    fontFamily: "Poppins-Regular",
                    fontWeight: "600",
                    fontSize: isOpen ? "16px" : "22px",
                    lineHeight: "22px",
                    color:
                      isOpen === true
                        ? "#FFFFFF !important"
                        : "#00ADB5 !important",
                    minWidth: "0px",
                    height: "auto",
                    background:
                      isOpen === true
                        ? "#00ADB5  !important"
                        : "#FFFFFF !important",
                    borderRadius: "18px",
                    "& span.MuiButton-icon": {
                      display: isOpen ? "none" : "block",
                      margin: "0px",
                      lineHeight: "12px",
                      "& svg": {
                        height: "31px",
                        width: "31px",
                      },
                    },
                  }}
                  endIcon={<ChevronRightIcon />}
                >
                  Save Genres
                </Button>
                <IconButton
                  aria-label="close"
                  onClick={handleSelectGenresOpen}
                  sx={{
                    backgroundColor: "#00ADB5",
                    height: "34px",
                    width: "34px",
                    "& svg": {
                      color: "white",
                    },
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
            {Object.keys(groupedData).map((category, index) => {
              return (
                <div key={index}>
                  <Accordion
                    sx={{ border: "none" }}
                    expanded={expanded === category}
                    onChange={handleChange(category)}
                  >
                    <AccordionSummary
                      sx={{
                        backgroundColor: "unset",
                        padding: "0px",
                        color: "#000000",
                        minHeight: "40px",
                      }}
                      aria-controls={`${category}-content`}
                      id={`${category}-header`}
                    >
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: "16px",
                          fontWeight: "600",
                          lineHeight: "18px",
                        }}
                      >
                        {category}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ border: "0px" }}>
                      {groupedData[category]
                        .slice()
                        .sort((a: any, b: any) => a.nameIndex - b.nameIndex)
                        .map((genre: any, index: any) => (
                          <div key={index}>
                            <CustomCheckbox
                              sx={{
                                marginTop: "10px",
                                "& svg": { fontSize: "16px" },
                                "& svg.MuiSvgIcon-root path": {
                                  border: "1px solid #00000080 !important",
                                  fill: "#00ADB5",
                                  borderRadius: "3px",
                                },
                                "& .css-hqkw6i-MuiButtonBase-root-MuiCheckbox-root":
                                  {
                                    padding: 0,
                                  },
                              }}
                              checked={isChecked(genre)}
                              onChange={() => handleCheckboxChange(genre)}
                              label={genre.name}
                              disabled={
                                !isChecked(genre) && selectedGenres.length >= 5
                              }
                              selectingGenres={selectingGenres}
                            />
                          </div>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
          </Box>
        </Grid>

        <Grid
          item
          xs={
            isExtraSmallScreen
              ? 12
              : isSmallScreen
              ? 7.5
              : isMediumScreen || isLargeScreen || isExtraLargeScreen
              ? 8.5
              : 7
          }
          sx={{
            padding: "0 !important",
            paddingTop:
              isMediumScreen || isLargeScreen || isExtraLargeScreen
                ? "35px !important"
                : "0 !important",
            marginTop: isExtraSmallScreen ? "60px" : "0px",
            borderLeft: isExtraSmallScreen
              ? "0 !important"
              : "1px solid #00000040",
          }}
        >
          <Box
            sx={{
              flex: "1",
              padding: isExtraSmallScreen
                ? "10px 32px 59px 32px"
                : "32px 32px 59px 32px",
              maxHeight: isExtraSmallScreen ? "unset" : "100vh",
              overflow: isExtraSmallScreen ? "auto" : "scroll",
            }}
          >
            {isMediumScreen && (
              <>
                {!isExtraSmallScreen && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: isOpen ? "flex-end" : "flex-start",
                      background: themeColors["#FFFFFF"],
                      zIndex: 999,
                      position: "fixed",
                      top: "80px",
                      flexDirection: "column",
                      paddingTop: "16px",
                    }}
                  >
                    <Button
                      onClick={handleFilterOptionOpen}
                      sx={{
                        fontFamily: "Poppins-Regular",
                        fontWeight: "600",
                        fontSize: "22px",
                        lineHeight: "22px",
                        color: "#00ADB5",
                        paddingLeft: "0px",
                        height: "auto",
                        "& span.MuiButton-icon": {
                          transform: filterOptionOpen
                            ? "rotate(92deg)"
                            : "rotate(0deg)",
                          margin: "0px",
                          lineHeight: "12px",
                          "& svg": {
                            height: "31px",
                            width: "31px",
                          },
                        },
                      }}
                      endIcon={<ChevronRightIcon />}
                    >
                      Filter Options
                    </Button>
                  </Box>
                )}
                {filterOptionOpen && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      overflow: "hidden",
                      position: "fixed",
                      zIndex: "999",
                      background: "white",
                      padding: "32px",
                      left: !isExtraSmallScreen ? "unset" : 0,
                      top: !isExtraSmallScreen ? "136px" : "175px",
                      height: "calc(100vh - 175px)",
                      width: !isExtraSmallScreen ? "auto" : "100%",
                      boxShadow: !isExtraSmallScreen
                        ? "0px 0px 20px 1px #0000001f"
                        : "0",
                    }}
                  >
                    <Box sx={{ marginBottom: "20px" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#00ADB5",
                          fontFamily: themeFonts["Poppins-SemiBold"],
                          paddingBottom: "8px",
                          lineHeight: "20px",
                        }}
                      >
                        Sort by
                      </Typography>

                      <FormControl style={{ width: "100%" }}>
                        <Select
                          className="sort-by-select"
                          value={sortBy}
                          onChange={handleStateChange}
                          IconComponent={SortArrow}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                "& ul": {
                                  border: "0.5px solid #00000033 !important",
                                  padding: "12px 16px !important",
                                  boxShadow:
                                    "10px 10px 20px 0px #0000001A !important",
                                },
                              },
                            },
                          }}
                          sx={{
                            color: "#686868",
                            fontFamily: themeFonts["Poppins-Regular"],
                            backgroundColor: "#f0f0f0",
                            fontSize: "14px",
                            borderRadius: "5px !important",
                            maxHeight: "39px",
                            "& fieldset.MuiOutlinedInput-notchedOutline": {
                              border: "0px",
                            },
                            "& .MuiInputBase-input": {
                              padding: "10px 0px !important",
                              textAlign: "center",
                              display: "inline-block",
                              width: "100%",
                            },
                            "& .MuiSelect-icon": {
                              display: "none",
                            },
                          }}
                        >
                          <MenuItem
                            value="followersLowToHigh"
                            sx={{
                              color: "#000000",
                              padding: "10px 0px",
                              backgroundColor: "#FFFFFF !important",
                              fontSize: "16px",
                              fontFamily: themeFonts["Poppins-SemiBold"],
                              borderBottom: "0.5px solid #C8C8C8 !important",
                            }}
                          >
                            Followers: Low to High
                          </MenuItem>
                          <MenuItem
                            value="followersHighToLow"
                            sx={{
                              color: "#000000",
                              padding: "10px 0px",
                              backgroundColor: "#FFFFFF !important",
                              fontSize: "16px",
                              fontFamily: themeFonts["Poppins-SemiBold"],
                              borderBottom: "0.5px solid #C8C8C8 !important",
                            }}
                          >
                            Followers: High to Low
                          </MenuItem>
                          <MenuItem
                            value="trackLowToHigh"
                            sx={{
                              color: "#000000",
                              padding: "10px 0px",
                              backgroundColor: "#FFFFFF !important",
                              fontSize: "16px",
                              fontFamily: themeFonts["Poppins-SemiBold"],
                              borderBottom: "0.5px solid #C8C8C8 !important",
                            }}
                          >
                            Track: Low to High
                          </MenuItem>
                          <MenuItem
                            value="trackHighToLow"
                            sx={{
                              color: "#000000",
                              padding: "10px 0px",
                              backgroundColor: "#FFFFFF !important",
                              fontSize: "16px",
                              fontFamily: themeFonts["Poppins-SemiBold"],
                              borderBottom: "0.5px solid #C8C8C8 !important",
                            }}
                          >
                            Track: High to Low
                          </MenuItem>
                          <MenuItem
                            value="curatorActivity"
                            sx={{
                              color: "#000000",
                              padding: "10px 0px",
                              backgroundColor: "#FFFFFF !important",
                              fontSize: "16px",
                              fontFamily: themeFonts["Poppins-SemiBold"],
                            }}
                          >
                            Curator Activity
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <Divider
                        sx={{ width: "100%", marginTop: "32px !important" }}
                      />
                    </Box>

                    <Box
                      className="searchPadding"
                      sx={{ marginBottom: "16px" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#00ADB5",
                          fontFamily: themeFonts["Poppins-SemiBold"],
                          marginBottom: "10px",
                          lineHeight: "30px",
                        }}
                      >
                        Search Playlists
                      </Typography>

                      <TextField
                        id="search"
                        type="search"
                        placeholder="Search Playlists by Title, URL, or Curator"
                        onChange={handleSearchChange}
                        value={searchValue}
                        InputProps={{
                          classes: {
                            input:
                              "css-nyra02-MuiInputBase-input-MuiOutlinedInput-input",
                          },
                        }}
                        sx={{
                          fontSize: "14px",
                          color: "#686868",
                          fontFamily: themeFonts["Poppins-Regular"],
                          backgroundColor: "#F0F0F0",
                          textAlign: "center",
                          width: "100%",
                          border: "none",
                          borderRadius: "5px",
                          outline: "none",
                          "& .MuiOutlinedInput-root": {
                            maxHeight: "39px",
                            "& fieldset": {
                              border: "none",
                            },
                            "& input::placeholder": {
                              color: "#686868",
                              fontFamily: themeFonts["Poppins-Regular"],
                              fontSize: "14px",
                              opacity: "1",
                            },
                            "& .MuiFormControl-root .MuiOutlinedInput-root": {
                              maxHeight: "39px",
                            },
                          },
                        }}
                      />
                      <Divider
                        sx={{ width: "100%", marginTop: "20px !important" }}
                      />
                    </Box>

                    {/* <Box sx={{ marginBottom: "20px" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#000000",
                          fontFamily: themeFonts["Poppins-Medium"],
                          display: "flex",
                          gap: "9px",
                          textTransform: "capitalize",
                        }}
                      >
                        Only by PitchPlaylists promoted playlists
                        <FormGroup>
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{
                              gap: "5px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#000000",
                                fontFamily: themeFonts["Poppins-Regular"],
                              }}
                            >
                              {checked ? "Yes" : "No"}
                            </Typography>
                            <AntSwitch
                              checked={checked}
                              onChange={handleSwitchChange}
                              inputProps={{ "aria-label": "ant design" }}
                            />
                          </Stack>
                        </FormGroup>
                      </Typography>
                      <Divider
                        sx={{ width: "100%", marginTop: "17px !important" }}
                      />
                    </Box> */}

                    <Box className="searchPadding">
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#00ADB5",
                          fontFamily: themeFonts["Poppins-SemiBold"],
                          marginBottom: "16px",
                          lineHeight: "30px",
                        }}
                      >
                        Countries
                      </Typography>

                      <TextField
                        id="search"
                        type="search"
                        placeholder="Filter By Curator Country"
                        // value={searchTerm}
                        InputProps={{
                          classes: {
                            input:
                              "css-nyra02-MuiInputBase-input-MuiOutlinedInput-input",
                          },
                        }}
                        sx={{
                          fontSize: "14px",
                          color: "#686868",
                          fontFamily: themeFonts["Poppins-Regular"],
                          backgroundColor: "#F0F0F0",
                          textAlign: "center",
                          width: "100%",
                          border: "none",
                          borderRadius: "5px",
                          padding: "0px",
                          outline: "none",
                          "& .MuiOutlinedInput-root": {
                            maxHeight: "39px",
                            borderRadius: "5px",
                            "& fieldset": {
                              border: "none",
                            },
                            "& input::placeholder": {
                              color: "#686868",
                              fontFamily: themeFonts["Poppins-Regular"],
                              fontSize: "14px",
                              opacity: "1",
                            },
                          },
                        }}
                        onChange={handleSearchByCountries}
                      />
                    </Box>
                  </Grid>
                )}
                <Grid
                  sx={{
                    width: "100%",
                    paddingTop: "20px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#FFFFFF",
                      marginBottom: "15px",
                    }}
                  >
                    <iframe
                      src={`https://open.spotify.com/embed/track/${trackData}`}
                      width="100%"
                      height="80"
                      frameBorder="0"
                      allowTransparency={true}
                      allow="encrypted-media; clipboard-write;"
                      title="playlist"
                    />
                  </Box>
                </Grid>
              </>
            )}

            <Typography
              sx={{
                fontSize: "30px",
                fontFamily: themeFonts["Poppins-SemiBold"],
                color: "#00ADB5",
                textAlign:
                  isMediumScreen || isLargeScreen || isExtraLargeScreen
                    ? "center"
                    : "start",
                marginBottom: "10px",
              }}
            >
              Click on the empty checkbox to select playlists!
            </Typography>

            {!isExtraSmallScreen && selectedGenres.length > 0 && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    color: " #00000080",
                    marginRight: "15px",
                    fontFamily: themeFonts["Poppins-Medium"],
                    fontSize: "16px",
                  }}
                >
                  Selected genres:
                </Typography>
                <Box>
                  {selectedGenres?.map(
                    (
                      selectedGenres: any,
                      index: React.Key | null | undefined
                    ) => {
                      return (
                        <>
                          <Box
                            key={index}
                            sx={{ display: "inline-block", margin: "6px" }}
                          >
                            <Button
                              onClick={() => handleRemoveGenre(selectedGenres)}
                              sx={{
                                backgroundColor: "#ffffff",
                                // border:"0.5px solid #00000080 !important",
                                border: "0.5px solid #00ADB5 !important",
                                fontSize: "16px",
                                borderRadius: "50px !important",
                                fontFamily: themeFonts["Poppins-Regular"],
                                color: "#00ADB5",
                                gap: "10px",
                                height: "28px",
                                padding: "1px 15px",
                                "&:hover": {
                                  backgroundColor: "#ffffff",
                                  color: "#00ADB5",
                                },
                              }}
                            >
                              {selectedGenres.name}{" "}
                              <CloseIconBlue style={{ fontSize: "9px" }} />
                            </Button>
                          </Box>
                        </>
                      );
                    }
                  )}
                </Box>
              </Box>
            )}
            <Box
              sx={{
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#00000080",
                  padding: "5px 14px",
                  borderBottom: "0.5px solid #00000040",
                  width: "auto",
                  textAlign: "center",
                  display: "inline-block",
                }}
              >
                Showing {filteredPlaylists?.length} out of {playlist?.length}{" "}
                total playlists
              </Typography>
            </Box>
            {/* {(isMediumScreen || isLargeScreen||isExtraLargeScreen) && selectedItems.playlistIds.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "24px",
                }}
              >
                <Button
                  onClick={handleOpen}
                  className=" margin-0"
                  variant="contained"
                  sx={{
                    padding: "11px 20px",
                    backgroundColor: "#00ADB5",
                    color: "#fff",
                    borderRadius: "33px",
                    height: "auto",
                  }}
                >
                  Continue
                </Button>
              </Box>
            )} */}
            <Grid
              item
              sx={{
                display: "flex",
                flexWrap: "wrap",
                marginTop: "24px",
                marginLeft: "-12px",
                marginRight: "-12px",
                justifyContent: isExtraSmallScreen ? "center" : "unset",
              }}
            >
              {filteredPlaylists?.map((playlistItem: any, index) => (
                <Box
                  sx={{
                    flex: "0 0 auto",
                    width: isTabletScreen
                      ? "100%"
                      : isLaptopScreen
                      ? "50%"
                      : "33.33333% ",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    marginBottom: "24px",
                    minWidth: "285px",
                  }}
                >
                  {" "}
                  <Grid
                    sx={{
                      boxShadow: "4px 4px 4px 0px #0000001A",
                      border: isPlaylistChecked(playlistItem._id)
                        ? "2px solid rgba(0, 173, 181, 1)"
                        : "1px solid rgba(0, 0, 0, 0.3)",
                      backgroundColor: playlistItem.isAlreadyAdded
                        ? "#DCFDFF"
                        : playlistItem.isOwnPlaylist
                        ? "#DCFDFF"
                        : playlistItem.isDisabled
                        ? "#DCFDFF"
                        : "#ffffff",
                      cursor: "pointer",
                      paddingBottom: "10px",
                    }}
                  >
                    <Link
                      to={"https://open.spotify.com/"}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          background: "#1DB954",
                        }}
                      >
                        <Box
                          sx={{
                            height: "42px",
                            width: "91px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={SpotifyWhiteLogo}
                            alt=""
                            style={{
                              width: "70px",
                              height: "21px",
                            }}
                          />
                        </Box>
                      </Box>
                    </Link>
                    <Box
                      sx={{
                        marginBottom: "12px",
                        paddingX: "16px",
                        paddingTop: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "12px",
                        }}
                        onClick={() =>
                          handleBoxClick(playlistItem._id, playlistItem)
                        }
                      >
                        <Link
                          to={playlistItem.playlistUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            width: "100px",
                            height: "100px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100px",
                              height: "100px",
                            }}
                          >
                            <img
                              src={playlistItem.imageUrl}
                              alt=""
                              className="img-fluid"
                              onClick={handlePropagation}
                            />
                          </Box>
                        </Link>
                        <Box
                          sx={{
                            flex: "1",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box onClick={handlePropagation}>
                              <MuiLink
                                component={Link}
                                to={playlistItem.playlistUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                  fontSize: "16px",
                                  color: "#000000",
                                  fontFamily: themeFonts["Poppins-SemiBold"],
                                  display: "-webkit-box",
                                  WebkitLineClamp: "2",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  textDecoration: "none",
                                  wordWrap: "break-word",
                                  maxWidth: "150px",
                                  "&:hover": {
                                    textDecoration: "underline",
                                  },
                                }}
                              >
                                {truncateString(playlistItem.playlistName, 25)}
                              </MuiLink>
                              <Box
                                sx={{
                                  display: "flex",
                                  gridGap: "3px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "10px",
                                    fontWeight: "500",
                                    color: "#00ADB5",
                                    textTransform: "capitalize",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textDecoration: "none",
                                  }}
                                >
                                  (By{" "}
                                </Typography>

                                <MuiLink
                                  component={Link}
                                  to={playlistItem?.userId?.profileUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  sx={{
                                    fontSize: "10px",
                                    fontWeight: "500",
                                    color: "#00ADB5",
                                    textTransform: "capitalize",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textDecoration: "none",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                >
                                  {playlistItem.ownerName}
                                </MuiLink>
                                <Typography
                                  sx={{
                                    fontSize: "10px",
                                    fontWeight: "500",
                                    color: "#00ADB5",
                                    textTransform: "capitalize",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textDecoration: "none",
                                    marginLeft: "-2px",
                                  }}
                                >
                                  )
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              {playlistItem.isAlreadyAdded ||
                              playlistItem.isOwnPlaylist ||
                              playlistItem.isDisabled ? (
                                <HtmlTooltip
                                  title={
                                    <Typography
                                      sx={{
                                        fontSize: "13px",
                                        color: "#000000",
                                        padding: "4px",
                                        fontFamily:
                                          themeFonts["Poppins-Medium"],
                                        width: "170px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {playlistItem.isOwnPlaylist
                                        ? "You are not allowed to submit a song to your own playlist"
                                        : playlistItem.isDisabled
                                        ? "Track can be submitted from up to three accounts"
                                        : "This track has already been submitted to this playlist"}
                                    </Typography>
                                  }
                                  placement="top"
                                  arrow
                                >
                                  <InformationIconGr
                                    style={{
                                      width: "13px",
                                      height: "12px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </HtmlTooltip>
                              ) : (
                                <Checkbox
                                  checked={isPlaylistChecked(playlistItem._id)}
                                  // onChange={() =>
                                  //   handlePlaylistCheckboxChange(
                                  //     playlistItem._id,
                                  //     playlistItem
                                  //   )
                                  // }
                                  sx={{
                                    "& svg": { fontSize: "24px" },
                                    "& .MuiSvgIcon-root": {
                                      borderRadius: "40px !important",
                                      "& path": {
                                        fill: "#00ADB5",
                                      },
                                    },
                                    padding: 0,
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                          {/* <Box>
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "#00000080",
                                display: "flex",
                                gap: "2px",
                                marginTop: "10px",
                                alignItems: "center",
                              }}
                              onClick={handlePropagation}
                            >
                              <HtmlTooltip
                                title={
                                  <Typography
                                    sx={{
                                      fontSize: "13px",
                                      color: "#000000",
                                      lineHeight: "19.5px !important",
                                      fontFamily: themeFonts["Poppins-Medium"],
                                      textTransform: "capitalize",
                                      maxWidth: "255px",
                                    }}
                                  >
                                    The fewer tracks a curator playlist
                                    receives, the greater the chance your song
                                    has of being selected. Reduced competition
                                    improves your odds
                                  </Typography>
                                }
                                placement="top"
                                arrow
                              >
                                <Button
                                  style={{
                                    padding: "0px",
                                    margin: "0px",
                                    minWidth: "5px",
                                    minHeight: "5px",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                >
                                  <InformationIconGr />
                                </Button>
                              </HtmlTooltip>
                              Current Submissions:{" "}
                              {playlistItem.totalSubmissions}
                            </Typography>
                          </Box> */}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            marginTop: "12px",
                          }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              gap: "10px",
                              fontSize: "10px",
                              color: "#00000080",
                            }}
                            onClick={handlePropagation}
                          >
                            Followers :
                            <Typography
                              sx={{
                                color: "#00ADB5",
                                fontSize: "10px",
                              }}
                            >
                              {playlistItem.totalFollowers}
                            </Typography>
                          </Typography>
                          <Typography
                            sx={{
                              display: "flex",
                              gap: "10px",
                              fontSize: "10px",
                              color: "#00000080",
                              marginTop: "6px",
                            }}
                            onClick={handlePropagation}
                          >
                            # Of Tracks :
                            <Typography
                              sx={{
                                color: "#00ADB5",
                                fontSize: "10px",
                              }}
                            >
                              {playlistItem.totalTracks}
                            </Typography>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            marginTop: "auto",
                          }}
                        >
                          {/* <Typography
                            sx={{
                              fontSize: "12px",
                              color:
                                playlistItem.userId?.currentStatus === "Active"
                                  ? "#0BD67E"
                                  : playlistItem.userId?.currentStatus ===
                                    "Not Active Recently"
                                  ? "#FF0000"
                                  : "#FF7A00",
                              fontFamily: themeFonts["Poppins-SemiBold"],
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                            onClick={handlePropagation}
                          >
                            <FiberManualRecord
                              sx={{
                                width: "6px",
                                height: "6px",
                              }}
                            />
                            {playlistItem.userId?.currentStatus}
                          </Typography> */}
                        </Box>
                      </Box>
                    </Box>

                    {/* <Box
                      sx={{
                        paddingTop: "10px",
                        paddingBottom: "0px",
                        borderTop: "0.5px solid #00000040",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingX: "16px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: "#00000080",
                        }}
                        onClick={handlePropagation}
                      >
                        Curator From: {playlistItem.country}
                      </Typography>
                    </Box> */}
                  </Grid>
                </Box>
              ))}
            </Grid>
          </Box>
        </Grid>
        {!isMediumScreen && (
          <Grid
            item
            xs={2.5}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              flexDirection: "row",
              borderLeft: "1px solid #00000040",
              paddingRight: "0px",
              paddingLeft: "20px !important",
              paddingY: "0px !important",
              minWidth: "360px",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: "32px 0 25px 0px",
                height: "calc(100vh - 58px)",
                overflow: "auto",
                scrollbarGutter: "stable both-edges",
                paddingRight: "18px",
              }}
            >
              <Box sx={{ marginBottom: "20px" }}>
                <Typography
                  sx={{
                    fontSize: "22px",
                    color: "#00ADB5",
                    fontFamily: themeFonts["Poppins-SemiBold"],
                    paddingBottom: "8px",
                  }}
                >
                  Sort by
                </Typography>

                <FormControl style={{ width: "100%" }}>
                  <Select
                    className="sort-by-select"
                    value={sortBy}
                    onChange={handleStateChange}
                    IconComponent={SortArrow}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          "& ul": {
                            border: "0.5px solid #00000033 !important",
                            padding: "12px 16px !important",
                            boxShadow:
                              "10px 10px 20px 0px #0000001A !important",
                          },
                        },
                      },
                    }}
                    sx={{
                      color: "#686868",
                      fontFamily: themeFonts["Poppins-Regular"],
                      backgroundColor: "#f0f0f0",
                      fontSize: "14px",
                      borderRadius: "5px !important",
                      maxHeight: "39px",
                      "& fieldset.MuiOutlinedInput-notchedOutline": {
                        border: "0px",
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 0px !important",
                        textAlign: "center",
                        display: "inline-block",
                        width: "100%",
                      },
                      "& .MuiSelect-icon": {
                        display: "none",
                      },
                    }}
                  >
                    <MenuItem
                      value="followersLowToHigh"
                      sx={{
                        color: "#000000",
                        padding: "5px 0px",
                        backgroundColor: "#FFFFFF !important",
                        fontSize: "12px",
                        fontFamily: themeFonts["Poppins-SemiBold"],
                        borderBottom: "0.5px solid #C8C8C8 !important",
                        justifyContent: "center",
                      }}
                    >
                      Followers: Low to High
                    </MenuItem>
                    <MenuItem
                      value="followersHighToLow"
                      sx={{
                        color: "#000000",
                        padding: "5px 0px",
                        backgroundColor: "#FFFFFF !important",
                        fontSize: "12px",
                        fontFamily: themeFonts["Poppins-SemiBold"],
                        borderBottom: "0.5px solid #C8C8C8 !important",
                        justifyContent: "center",
                      }}
                    >
                      Followers: High to Low
                    </MenuItem>
                    <MenuItem
                      value="trackLowToHigh"
                      sx={{
                        color: "#000000",
                        padding: "5px 0px",
                        backgroundColor: "#FFFFFF !important",
                        fontSize: "12px",
                        fontFamily: themeFonts["Poppins-SemiBold"],
                        borderBottom: "0.5px solid #C8C8C8 !important",
                        justifyContent: "center",
                      }}
                    >
                      Track: Low to High
                    </MenuItem>
                    <MenuItem
                      value="trackHighToLow"
                      sx={{
                        color: "#000000",
                        padding: "5px 0px",
                        backgroundColor: "#FFFFFF !important",
                        fontSize: "12px",
                        fontFamily: themeFonts["Poppins-SemiBold"],
                        borderBottom: "0.5px solid #C8C8C8 !important",
                        justifyContent: "center",
                      }}
                    >
                      Track: High to Low
                    </MenuItem>
                    {/* <MenuItem
                      value="curatorActivity"
                      sx={{
                        color: "#000000",
                        padding: "5px 0px",
                        backgroundColor: "#FFFFFF !important",
                        fontSize: "12px",
                        fontFamily: themeFonts["Poppins-SemiBold"],
                        justifyContent: "center",
                      }}
                    >
                      Curator Activity
                    </MenuItem> */}
                  </Select>
                </FormControl>

                <Divider sx={{ width: "100%", marginTop: "32px !important" }} />
              </Box>

              <Box className="searchPadding" sx={{ marginBottom: "16px" }}>
                <Typography
                  sx={{
                    fontSize: "22px",
                    color: "#00ADB5",
                    fontFamily: themeFonts["Poppins-SemiBold"],
                    marginBottom: "10px",
                  }}
                >
                  Search Playlists
                </Typography>

                <TextField
                  id="search"
                  type="search"
                  placeholder="Search Playlists by Title, URL, or Curator"
                  onChange={handleSearchChange}
                  value={searchValue}
                  InputProps={{
                    classes: {
                      input:
                        "css-nyra02-MuiInputBase-input-MuiOutlinedInput-input",
                    },
                  }}
                  sx={{
                    fontSize: "14px",
                    color: "#686868",
                    fontFamily: themeFonts["Poppins-Regular"],
                    backgroundColor: "#F0F0F0",
                    textAlign: "center",
                    width: "100%",
                    border: "none",
                    borderRadius: "5px",
                    outline: "none",
                    "& .MuiOutlinedInput-root": {
                      maxHeight: "39px",
                      "& fieldset": {
                        border: "none",
                      },
                      "& input::placeholder": {
                        color: "#686868",
                        fontFamily: themeFonts["Poppins-Regular"],
                        fontSize: "14px",
                        opacity: "1",
                      },
                      "& .MuiFormControl-root .MuiOutlinedInput-root": {
                        maxHeight: "39px",
                      },
                    },
                  }}
                />
                <Divider sx={{ width: "100%", marginTop: "20px !important" }} />
              </Box>

              {/* <Box sx={{ marginBottom: "20px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#000000",
                    fontFamily: themeFonts["Poppins-Medium"],
                    display: "flex",
                    gap: "9px",
                    textTransform: "capitalize",
                    justifyContent: "space-between",
                  }}
                >
                  Playlists promoted by PitchPlaylists
                  <FormGroup>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        gap: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#000000",
                          fontFamily: themeFonts["Poppins-Regular"],
                        }}
                      >
                        {checked ? "Yes" : "No"}
                      </Typography>
                      <AntSwitch
                        checked={checked}
                        onChange={handleSwitchChange}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                    </Stack>
                  </FormGroup>
                </Typography>
                <Divider sx={{ width: "100%", marginTop: "17px !important" }} />
              </Box> */}

              {/* <Box className="searchPadding" sx={{ marginBottom: "20px" }}>
                <Typography
                  sx={{
                    fontSize: "22px",
                    color: "#00ADB5",
                    fontFamily: themeFonts["Poppins-SemiBold"],
                    marginBottom: "16px",
                  }}
                >
                  Countries
                </Typography>

                <TextField
                  id="search"
                  type="search"
                  placeholder="Filter By Curator Country"
                  // value={searchTerm}
                  InputProps={{
                    classes: {
                      input:
                        "css-nyra02-MuiInputBase-input-MuiOutlinedInput-input",
                    },
                  }}
                  sx={{
                    fontSize: "14px",
                    color: "#686868",
                    fontFamily: themeFonts["Poppins-Regular"],
                    backgroundColor: "#F0F0F0",
                    textAlign: "center",
                    width: "100%",
                    border: "none",
                    borderRadius: "5px",
                    padding: "0px",
                    outline: "none",
                    "& .MuiOutlinedInput-root": {
                      maxHeight: "39px",
                      borderRadius: "5px",
                      "& fieldset": {
                        border: "none",
                      },
                      "& input::placeholder": {
                        color: "#686868",
                        fontFamily: themeFonts["Poppins-Regular"],
                        fontSize: "14px",
                        opacity: "1",
                      },
                    },
                  }}
                  onChange={handleSearchByCountries}
                />
              </Box> */}

              {/* <Grid
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#FFFFFF",
                    marginBottom: "15px",
                  }}
                >
                  <iframe
                    src={`https://open.spotify.com/embed/track/${trackData}`}
                    width="100%"
                    height="80"
                    frameBorder="0"
                    allowTransparency={true}
                    allow="encrypted-media; clipboard-write;"
                    title="playlist"
                  />
                </Box>
              </Grid> */}
              {selectedItems.playlistIds.length > 0 && (
                // !isLargeScreen &&
                // !isExtraLargeScreen &&
                <>
                  {/* <Box
                      sx={{
                        padding: "24px 32px",
                        background: `url(${LogBackground})`,
                        textAlign: "center",
                      }}
                    >
                      <Chip
                        label={`${
                          remainingSubmissions === 1 ||
                          remainingSubmissions === 0
                            ? `${remainingSubmissions} submission remaining`
                            : `${remainingSubmissions} submissions remaining`
                        }`}
                        sx={{
                          color:
                            remainingSubmissions === 0
                              ? themeColors["#00ADB5"]
                              : "#FFFFFF",
                          fontSize: "16px",
                          fontFamily: themeFonts["Poppins-Medium"],
                          background:
                            remainingSubmissions === 0
                              ? themeColors["#FFFFFF"]
                              : themeColors["#00000082"],
                          height: "45px",
                          paddingX: "12px",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          marginBottom: "12px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontFamily: themeFonts["Poppins-Medium"],
                          color: "#FFFFFF",
                          textAlign: "center",
                        }}
                      >
                        {`Your Submission limit will reset on ${format(
                          nextMonday,
                          "dd/MM/yyyy"
                        )}`}
                      </Typography>

                      <Button
                        onClick={handleOpenButton}
                        sx={{
                          fontSize: "18px",
                          fontFamily: themeFonts["Poppins-Bold"],
                          color: "#FFFFFF",
                          marginBottom: "10px",
                          minHeight: "29px",
                          "&:hover": {
                            background: "unset",
                          },
                        }}
                      >
                        Want more submissions?
                      </Button>
                    </Box> */}

                  <Box
                    sx={{
                      textAlign: "center",
                      marginTop: "10px",
                      position: "sticky",
                      bottom: "0px",
                    }}
                  >
                    <Button
                      // onClick={handleOpen}
                      onClick={handleSubmit}
                      className=" margin-0"
                      variant="contained"
                      sx={{
                        marginLeft: "auto",
                        padding: "11px 39px",
                        backgroundColor: "#00ADB5",
                        color: "#fff",
                        borderRadius: "33px",
                        height: "auto",
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* )} */}
      {open && (
        <SongSubmissionDialog
          open={open}
          onClose={handleClose}
          handleSubmit={handleSubmit}
          selectedPlaylists={selectedPlaylists}
          isLoading={isLoading}
        />
      )}

      {hasOpen && (
        <SelectMoreSubmissions
          hasOpen={hasOpen}
          handleCloseButton={handleCloseButton}
        />
      )}
      {isMediumScreen && selectedItems.playlistIds.length > 0 && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "16px",
            position: "fixed",
            bottom: 0,
            zIndex: 1,
          }}
        >
          <Button
            onClick={handleSubmit}
            className=" margin-0"
            variant="contained"
            sx={{
              padding: "11px 20px",
              backgroundColor: "#00ADB5",
              color: "#fff",
              borderRadius: "0px",
              height: "50px",
              width: "100%",
              fontSize: "20px",
              fontFamily: themeFonts["Poppins-Medium"],
            }}
          >
            Submit
          </Button>

          {/* <Box
            sx={{
              background: themeColors["#00ADB5"],
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              gap: "16px",
              paddingY: "16px",
            }}
          > */}
          {/* <Chip
                label={`${
                  remainingSubmissions === 1 || remainingSubmissions === 0
                    ? `${remainingSubmissions} submission remaining`
                    : `${remainingSubmissions} submissions remaining`
                }`}
                sx={{
                  color:
                    remainingSubmissions === 0
                      ? themeColors["#00ADB5"]
                      : "#FFFFFF",
                  fontSize: "16px",
                  fontFamily: themeFonts["Poppins-Medium"],
                  background:
                    remainingSubmissions === 0
                      ? themeColors["#FFFFFF"]
                      : themeColors["#00000082"],
                  height: "45px",
                  paddingX: "12px",
                  backgroundPosition: "center",
                }}
              /> */}
          {/* {(isLargeScreen || isExtraLargeScreen) && (
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: themeFonts["Poppins-Medium"],
                  color: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                {`Your Submission limit will reset on ${format(
                  nextMonday,
                  "dd/MM/yyyy"
                )}`}
              </Typography>
            )} */}
          {/* <Button
                onClick={handleOpenButton}
                sx={{
                  fontSize: "18px",
                  fontFamily: themeFonts["Poppins-Bold"],
                  color: "#FFFFFF",
                  height: "45px",
                  paddingX: "6px !important",
                  "&:hover": {
                    background: "unset",
                  },
                }}
              >
                Want more submissions?
              </Button> */}
          {/* </Box> */}
        </Box>
      )}
      <Footer />
    </Grid>
  );
};
