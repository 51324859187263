import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Link as MuiLink,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { OkIcon, CancelIcon } from "../../svgs";
import { useUpdatePlayListMutation } from "../../components/apis/playlist";
import { useGetTrackByIdQuery } from "../../components/apis/artistsApi";
import { differenceInDays, format } from "date-fns";
import { themeFonts } from "../../configs";
// import { useGetDayCountQuery } from "../../components/apis/warningDaysApi";
import { SpotifyGreenLogo } from "../../pngs";
import { Link } from "react-router-dom";

export const PlayListTrack = ({
  playlistId,
  trackItem,
  uri,
  refetch,
  userInfoRefetch,
  playlistItem,
}: {
  playlistId?: any;
  trackItem?: any;
  uri?: any;
  refetch?: any;
  userInfoRefetch?: any;
  playlistItem?: any;
}) => {
  // const { data: dayCount } = useGetDayCountQuery();
  //  const dayValue = dayCount?.daysCount[0]?.noOfDays

  // const dayValue =
  //   dayCount &&
  //   dayCount?.daysCount?.map((daysValue: any) => {
  //     return daysValue.noOfDays;
  //   });

  const [updatePlayListStatus] = useUpdatePlayListMutation();
  const id = localStorage.getItem("userId");
  const { refetch: trackRefetch } = useGetTrackByIdQuery<any>({
    userId: id,
  });
  const isMiniScreen = useMediaQuery("(max-width:605px)");
  const [approvedLoading, setApprovedLoading] = useState(false);
  const [declinedLoading, setDeclinedLoading] = useState(false);
  const handleApproved = async (trackId: any) => {
    setApprovedLoading(true);
    try {
      await updatePlayListStatus({
        playlistId: playlistId,
        status: "approved",
        uri: uri,
        declineReason: "",
        trackId: trackId,
      }).unwrap();
    } catch (error: any) {}
    refetch();
    trackRefetch();
    setTimeout(() => {
      userInfoRefetch();
    }, 1000);
  };

  const handleDecline = async (trackId: any) => {
    setDeclinedLoading(true);
    try {
      await updatePlayListStatus({
        playlistId: playlistId,
        status: "declined",
        uri: uri,
        declineReason: "",
        trackId: trackId,
      }).unwrap();
    } catch (error: any) {}
    refetch();
    trackRefetch();
    setTimeout(() => {
      userInfoRefetch();
    }, 1000);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    if (trackItem && trackItem.createdAt) {
      const submissionDate = new Date(trackItem.createdAt);

      const dateOnly = submissionDate.toISOString().split("T")[0];
      const dateOnlySubmissionDate = new Date(dateOnly);

      const expirationDate = new Date(dateOnlySubmissionDate);
      expirationDate.setDate(dateOnlySubmissionDate.getDate() + 22);

      const currentDate = new Date();
      const daysLeft = differenceInDays(expirationDate, currentDate);
      setDaysLeft(daysLeft);
    }
  }, [trackItem]);
  return (
    <Grid
      sx={{
        maxWidth: "600px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          boxShadow: "4px 4px 4px 0px #0000001A",
          padding: "15px",
        }}
      >
        <Box
          sx={{
            // margin: "auto",
            height: "54px",
            width: "117px",
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
          }}
        >
          <Link
            to={"https://open.spotify.com/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={SpotifyGreenLogo}
              alt=""
              style={{
                width: "90px",
                height: "27px",
              }}
            />
          </Link>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMiniScreen ? "column" : "row",
          }}
        >
          <Box
            sx={{
              display: "flex",
              // alignItems: "center",
              flex: 1,
              // justifyContent: "space-between",
            }}
          >
            <Box>
              <Link
                to={trackItem?.track?.trackUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  width={"90px"}
                  src={trackItem?.track?.trackImageUrl}
                  alt=""
                />
              </Link>
            </Box>
            <Box sx={{ padding: "0 10px 0px 10px" }}>
              <MuiLink
                component={Link}
                to={trackItem?.track?.trackUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  fontSize: "16px",
                  color: "#000000",
                  fontFamily: themeFonts["Poppins-Medium"],
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  wordWrap: "break-word",
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {trackItem?.track?.trackName}
              </MuiLink>
              {trackItem?.track?.artists.map((artist: any, index: any) => (
                <MuiLink
                  key={index}
                  component={Link}
                  to={artist?.external_urls?.spotify}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    fontSize: "13px",
                    color: "#9f9f9f",
                    fontFamily: themeFonts["Poppins-Medium"],
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    margin: "0 2px 0 0px",
                    wordWrap: "break-word",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {artist.name}
                  {index < trackItem?.track?.artists.length - 1 && ", "}
                </MuiLink>
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              flex: 1,
            }}
          >
            <iframe
              src={`https://open.spotify.com/embed/track/${trackItem.track.trackId}?utm_source=generator&theme=0`}
              width={"100%"}
              height={"87px"}
              title="playlist"
              frameBorder={0}
              allowTransparency={true}
            ></iframe>
            <Box
              sx={{
                display: "flex",
                justifyContent: isMiniScreen ? "center" : "end",
              }}
            >
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#00000080",
                  margin: "8px 0",
                }}
              >
                Submitted on:{" "}
                {format(new Date(trackItem.createdAt), "dd/MM/yyyy")}
              </Typography>

              {/* <Typography
            sx={{
              fontSize: "13px",
              color: "#00000080",
              margin: "16px 0",
            }}
          >
            Expires after {daysLeft} days
          </Typography> */}
            </Box>
            <Box
              className="flex-direction-column-xsm"
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={() => handleApproved(trackItem._id)}
                variant="contained"
                sx={{
                  padding: "11px 30px",
                  backgroundColor: "#00ADB5",
                  borderRadius: "33px",
                  color: "#fff",
                  fontSize: "14px",
                  height: "auto",
                  display: "flex",
                  gap: "5px",
                  // minWidth: "170px",
                }}
              >
                {approvedLoading ? (
                  <CircularProgress
                    size={17}
                    sx={{
                      color: "inherit",
                      fontWeight: "900",
                    }}
                  />
                ) : (
                  <>
                    <OkIcon /> Accept
                  </>
                )}
              </Button>
              <Button
                className="margin-0"
                onClick={() => handleDecline(trackItem._id)}
                variant="contained"
                sx={{
                  padding: "11px 30px",
                  backgroundColor: "#FF0000",
                  borderRadius: "33px",
                  color: "#fff",
                  fontSize: "14px",
                  height: "auto",
                  display: "flex",
                  gap: "5px",
                  // minWidth: "150px",
                }}
              >
                {declinedLoading ? (
                  <CircularProgress
                    size={17}
                    sx={{
                      color: "inherit",
                      fontWeight: "900",
                    }}
                  />
                ) : (
                  <>
                    <CancelIcon />
                    Decline
                  </>
                )}
              </Button>
            </Box>
          </Box>
        </Box>

        {/* <Typography
          sx={{
            fontSize: "10.7px",
            marginTop: "7px",
            color: "#00000080",
            fontFamily: themeFonts["Poppins-Medium"],
          }}
        >
          If{" "}
          <Box component="span" sx={{ color: "#00ADB5" }}>
            Accepted{" "}
          </Box>{" "}
          the song must stay in your playlist for at least{" "}
          <Box component="span" sx={{ color: "#00ADB5" }}>
            {dayValue} days!
          </Box>
        </Typography> */}
      </Box>
    </Grid>
  );
};
