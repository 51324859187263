import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  useMediaQuery,
  Link as MuiLink,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SpotifyGreenLogo, VisitPublicBg } from "../../pngs";
import { SearchComponents } from "../../components/filter/search-component";
import CloseIcon from "@mui/icons-material/Close";
import { BlackThumpsUpIcon, GreenDot } from "../../svgs";
import { useGetArtistListQuery } from "../../components/apis/artistsApi";
import {
  useGetPlayListByIdQuery,
  useGetPlayListDetailQuery,
  useGetPlayListQuery,
} from "../../components/apis/playlist";
import {
  useCreateTrackMutation,
  useGetTrackByIdQuery,
} from "../../components/apis/artistsApi";

import { ROUTES } from "../../components/consts/routes.consts";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { themeColors, themeFonts } from "../../configs";
// import { SongSubmissionDialog } from "../../components/modals/song-submission-modal";
import { toast } from "react-toastify";
import { useGetUsersListQuery } from "../../components/apis/userDetailsAPi";

function VisitPublicPage() {
  const userId = localStorage.getItem("userId");
  // const [selectedPlaylists, setSelectedPlaylists] = useState([]);
  const { refetch: playlistRefetch } = useGetPlayListQuery<any>();
  const { refetch: creditsRefetch } = useGetUsersListQuery<any>();
  const { refetch: trackRefetch } = useGetTrackByIdQuery({
    userId: userId,
  });
  const [searchInput, setSearchInput] = useState<string>("");
  const [submitTrack] = useCreateTrackMutation();
  const location = useLocation();
  const { trackData } = location.state || {};
  const [trackUrl, setTrackUrl] = useState("");
  const [trackUrlId, setTrackUrlId] = useState("");
  // const [isOpen, setIsOpen] = useState(false);
  // const handleCloseButton = () => {
  //   setIsOpen(false);
  // };
  // const handleOpen = () => {
  //   setIsOpen(true);
  // };
  const isMiniScreen = useMediaQuery("(max-width:500px)");
  const isMidiScreen = useMediaQuery("(max-width:768px)");
  const { ids } = useParams();
  const { data, refetch: PlayListByIdRefetch } = useGetPlayListByIdQuery({
    userId: userId,
  });
  const {
    data: playlistDetail,
    //  refetch: playlistDetailRefetch
  } = useGetPlayListDetailQuery({
    id: ids,
  });
  const filteredData =
    data &&
    data.userPlaylist.filter(
      (value: { _id: string | undefined }) => value._id === ids
    );
  const dataToRender = filteredData?.length
    ? filteredData
    : playlistDetail?.playlist;
  const filteredPlaylistsAlready = dataToRender?.map((track: any) => {
    return {
      ...track,
      isAlreadyAdded:
        track?.submittedTracks?.some(
          (submittedTrack: any) => submittedTrack?.track.trackId === trackData
        ) ?? false,
      isOwnPlaylist: track?.playlistOwnerId === userId,
    };
  });

  useEffect(() => {
    // setSelectedPlaylists(filteredPlaylistsAlready);
    setTrackUrl(trackData?.external_urls?.spotify);
    setTrackUrlId(trackData?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackData]);
  const handleCancel = () => {
    setSearchInput("");
    setTrackUrl("");
    setTrackUrlId("");
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isSmallScreen = useMediaQuery("(max-width:1100px)");
  const isMediumScreen = useMediaQuery("(max-width:1441px)");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const track = "https://open.spotify.com/track";
  const navigate = useNavigate();
  const parts = searchInput.split("/");
  const lastPart = parts[parts.length - 1];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [trackId, queryString] = lastPart.split("?");
  const trackID = trackId ? trackId : trackUrlId;
  const { data: songDetails, refetch } = useGetArtistListQuery<any>({
    trackId: trackID,
  });
  const handleConnect = async () => {
    // window.location.href = `http://localhost:9090/api/auth/login?trackId=${trackId}&playlistId=${ids}`;
    window.location.href = `https://api.pitchplaylists.com/api/auth/login?trackId=${trackId}&playlistId=${ids}`;
  };
  const handleSearchChange = (value: React.SetStateAction<string>) => {
    setSearchInput(value);
  };
  const Id = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const isLoggedIn = token && Id;
  useEffect(() => {
    if (searchInput.includes(track)) {
      if (isLoggedIn) {
        refetch({ trackId: trackID }).then((response: any) => {
          if (response.status !== "fulfilled") {
            navigate(ROUTES.PAGE_NOT_FOUND, {
              state: { playlistId: ids },
            });
          }
        });
      } else {
        handleConnect();
        refetch({ trackId: trackID });
      }
    } else {
      setTimeout(() => {
        setSearchInput("");
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, searchInput, token, trackID]);
  const isInvalid = searchInput !== "" && !searchInput.includes(track);
  const handleSubmit = async () => {
    try {
      const res = await submitTrack({
        trackId: trackID,
        playlistIds: [ids],
        id: userId,
      }).unwrap();
      toast.success("Success");
      if (res) {
        navigate(ROUTES.DASHBOARD);
      }
    } catch (error: any) {
      toast.error("Failed");
    }
    playlistRefetch();
    trackRefetch();
    PlayListByIdRefetch();
    creditsRefetch();
  };
  const truncateString = (str: any, num: any) => {
    if (str?.length <= num) {
      return str;
    }
    return str?.slice(0, num) + "...";
  };
  return (
    <Grid
      container
      spacing={3}
      sx={{
        marginTop: "-80px",
        flexDirection: "column",
        width: "100% !important",
        padding: "0 !important ",
        background: `url(${VisitPublicBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginX: "0 !important",
        overflow: "auto",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          width: "100%",
        }}
      >
        {filteredPlaylistsAlready?.map((item: any, index: any) => {
          const searchInputText = trackUrl !== "" ? true : false;
          const isTrackIncluded =
            searchInputText || searchInput.includes(track);

          const hasTrackID = !!trackID;
          const hasToken = !!token;
          const isNotOwnPlaylist = !item.isOwnPlaylist;
          const trackDetail =
            isTrackIncluded && hasTrackID && hasToken && isNotOwnPlaylist;
          return (
            <>
              <Box
                sx={{
                  display: "flex",
                  width: isSmallScreen
                    ? "auto"
                    : isMediumScreen
                    ? "90%"
                    : "70%",
                  justifyContent: "space-around",
                  padding: "32px",
                  gap: isSmallScreen ? "30px" : "0px",
                  flexDirection: isSmallScreen ? "column" : "row",
                  alignItems: isSmallScreen ? "center" : "unset",
                  marginTop: isMidiScreen ? "80px" : "0",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    maxWidth: "691px",
                    width: "100%",
                    flexWrap: isSmallScreen ? "wrap" : "unset",
                    justifyContent: isSmallScreen ? "center" : "unset",
                    gap: isSmallScreen ? " 12px" : "24px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <Link
                      to={item?.playlistUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={item?.imageUrl}
                        alt=""
                        width={"200px"}
                        height={"200px"}
                      />
                    </Link>
                  </Box>

                  <Box sx={{ textAlign: isSmallScreen ? "center" : "unset" }}>
                    <Box
                      sx={{
                        height: "54px",
                        width: "117px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Link
                        to={"https://open.spotify.com/"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={SpotifyGreenLogo}
                          alt=""
                          style={{
                            width: "90px",
                            height: "27px",
                          }}
                        />
                      </Link>
                    </Box>
                    <Box sx={{ paddingLeft: "13.5px" }}>
                      <MuiLink
                        component={Link}
                        to={item?.playlistUrl}
                        target="blank"
                        rel="noopener noreferrer"
                        sx={{
                          fontSize: "32px",
                          color: "#ffffff",
                          fontFamily: themeFonts["Poppins-SemiBold"],
                          display: "-webkit-box",
                          WebkitLineClamp: "2",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          wordWrap: "break-word",
                          textDecoration: "none",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                          lineHeight: "38px",
                        }}
                      >
                        {truncateString(item?.playlistName, 25)}
                      </MuiLink>
                      <Box
                        sx={{
                          display: "flex",
                          gridGap: "3px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: isSmallScreen ? "16px" : "20px",
                            fontWeight: "400",
                            color: "#00ADB5",
                          }}
                        >
                          (By
                        </Typography>
                        <MuiLink
                          component={Link}
                          to={item?.userId?.profileUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            fontSize: isSmallScreen ? "16px" : "20px",
                            fontWeight: "400",
                            color: "#00ADB5",
                            textTransform: "capitalize",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textDecoration: "none",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                        >
                          {item.ownerName}
                        </MuiLink>
                        <Typography
                          sx={{
                            fontSize: isSmallScreen ? "16px" : "20px",
                            fontWeight: "400",
                            color: "#00ADB5",
                            marginLeft: "-2px",
                          }}
                        >
                          )
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          marginTop: isSmallScreen ? "20px" : "10px",
                          textAlign: "start",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: isSmallScreen ? "16px" : "20px",
                            fontWeight: "500",
                            color: "#FFFFFF",
                            paddingBottom: "5px",
                          }}
                        >
                          {item?.totalFollowers} Followers
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: isSmallScreen ? "16px" : "20px",
                            fontWeight: "500",
                            color: "#FFFFFF",
                          }}
                        >
                          {item?.totalTracks} Tracks
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    maxWidth: "600px",
                    width: "100%",
                    textAlign: "center",
                    marginTop: isSmallScreen ? "30px" : "unset",
                  }}
                >
                  {trackDetail === true ? (
                    <>
                      <Typography
                        sx={{
                          fontSize: isMiniScreen ? "28px" : "36px",
                          fontWeight: "600",
                          color: "#ffffff",
                        }}
                      >
                        Your Song Info
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#ffffff",
                          margin: "14px 0px",
                        }}
                      >
                        Is this the track you want to submit?
                      </Typography>

                      <Box
                        sx={{
                          backgroundColor: "#FFFFFF",
                          boxShadow: "4px 4px 4px 0px #0000001A",
                          padding: "15px",
                        }}
                      >
                        <Box
                          sx={{
                            // margin: "auto",
                            height: "54px",
                            width: "117px",
                            // display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                          }}
                        >
                          <Link
                            to={"https://open.spotify.com/"}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={SpotifyGreenLogo}
                              alt=""
                              style={{
                                width: "90px",
                                height: "27px",
                              }}
                            />
                          </Link>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: isMidiScreen ? "column" : "row",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              // alignItems: "center",
                              flex: 1,
                              // justifyContent: "space-between",
                            }}
                          >
                            <Box>
                              <Link
                                to={"https://open.spotify.com/"}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  width={"90px"}
                                  src={songDetails?.album?.images[1].url}
                                  alt=""
                                />
                              </Link>
                            </Box>
                            <Box
                              sx={{
                                padding: "0 10px 0px 10px",
                                textAlign: "left",
                              }}
                            >
                              <MuiLink
                                component={Link}
                                to={songDetails?.external_urls?.spotify}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                  fontSize: "16px",
                                  color: "#000000",
                                  fontFamily: themeFonts["Poppins-Medium"],
                                  display: "-webkit-box",
                                  WebkitLineClamp: "2",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  wordWrap: "break-word",
                                  textDecoration: "none",
                                  "&:hover": {
                                    textDecoration: "underline",
                                  },
                                }}
                              >
                                {songDetails?.name}
                              </MuiLink>

                              {songDetails?.artists.map(
                                (artist: any, index: any) => (
                                  <MuiLink
                                    key={index}
                                    component={Link}
                                    to={artist?.external_urls?.spotify}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                      fontSize: "13px",
                                      color: "#9f9f9f",
                                      fontFamily: themeFonts["Poppins-Medium"],
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                      margin: "0 2px 0 0px",
                                      wordWrap: "break-word",
                                      textDecoration: "none",
                                      "&:hover": {
                                        textDecoration: "underline",
                                      },
                                    }}
                                  >
                                    {artist.name}
                                    {index < songDetails?.artists.length - 1 &&
                                      ", "}
                                  </MuiLink>
                                )
                              )}
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              flex: 1,
                            }}
                          >
                            <iframe
                              src={`https://open.spotify.com/embed/track/${trackID}`}
                              width="100%"
                              height="90"
                              frameBorder={0}
                              allowTransparency={true}
                              title="gj"
                            ></iframe>
                          </Box>
                        </Box>
                      </Box>

                      {/* <iframe
                          src={`https://open.spotify.com/embed/track/${trackID}`}
                          width="100%"
                          height="87px"
                          frameBorder={0}
                          allowTransparency={true}
                          title="gj"
                          allow="encrypted-media; clipboard-write"
                        ></iframe> */}

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "15px",
                        }}
                      >
                        {/* <MuiLink
                           onClick={handleCancel}
                          sx={{
                            color: themeColors["#FFFFFF"],
                            fontSize: "16px",
                            fontFamily: themeFonts["Poppins-Regular"],
                          }}
                        >
                          Cancel
                        </MuiLink> */}

                        <Button
                          sx={{
                            height: "auto",
                            padding: "0px",
                            color: themeColors["#FFFFFF"],
                            fontSize: "16px",
                            fontFamily: themeFonts["Poppins-Regular"],
                          }}
                          onClick={handleCancel}
                        >
                          {" "}
                          Cancel{" "}
                        </Button>

                        <Button
                          onClick={handleSubmit}
                          variant="outlined"
                          sx={{
                            color: themeColors["#000000"],
                            fontFamily: themeFonts["Poppins-Regular"],
                            border: "1px solid #FFFFFF",
                            backgroundColor: themeColors["#FFFFFF"],
                            height: "auto",
                            marginLeft: "auto",
                            padding: "8px 9px",
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                            fontSize: "13px",
                            "&:hover": {
                              background: themeColors["#FFFFFF"],
                              border: "1px solid #FFFFFF",
                            },
                          }}
                        >
                          <BlackThumpsUpIcon />
                          That's right, Submit
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Typography
                        sx={{
                          fontSize: isSmallScreen ? "18px" : "36px",
                          fontWeight: "600",
                          color: "#ffffff",
                          marginBottom: "20px",
                        }}
                      >
                        Submit your song
                      </Typography>

                      <SearchComponents
                        searchTitle={"Insert your Spotify track link"}
                        isEmpty={true}
                        onSearchChange={handleSearchChange}
                        isInvalid={isInvalid}
                        value={searchInput}
                        showIcon={true}
                      />
                      <Typography
                        sx={{
                          color: themeColors["#FF0000"],
                          fontFamily: themeFonts["Poppins-Medium"],
                          fontSize: ["14px"],
                          marginTop: "12px",
                        }}
                      >
                        {item.isOwnPlaylist &&
                          isTrackIncluded &&
                          hasTrackID &&
                          "You are not allowed to submit a song to your own playlist!"}
                      </Typography>
                      <Button
                        className="need-help-btn"
                        aria-describedby={id}
                        variant="contained"
                        onClick={handleClick}
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#fff",
                          marginTop: "16px",
                          textAlign: "end  !important",
                          cursor: "pointer",
                          background: "transparent",
                          padding: "0px",
                          height: "auto",
                          display: "flex",
                          marginLeft: "auto",
                        }}
                      >
                        Need Help?
                      </Button>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        sx={{
                          "& .MuiPopover-paper": {
                            boxShadow: "4px 4px 4px 0px #0000001A",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            padding: "12px",
                            minWidth: "251px",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "70px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "#000000",
                              }}
                            >
                              Using Spotify Track Links
                            </Typography>
                            <Box
                              sx={{
                                marginLeft: "auto",
                              }}
                            >
                              <CloseIcon
                                sx={{
                                  cursor: "pointer",
                                }}
                                onClick={handleClose}
                              ></CloseIcon>
                            </Box>
                          </Box>
                          <Box>
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                                paddingTop: "8px !important",
                                paddingBottom: "3px !important",
                              }}
                            >
                              <ListItem
                                sx={{
                                  padding: "0px 0px 16px 0px !important",
                                }}
                              >
                                <ListItemText
                                  sx={{
                                    "& span.MuiListItemText-primary": {
                                      lineHeight: "15px",
                                      display: "flex",
                                      fontSize: "12px",
                                      color: themeColors["#00000080"],
                                      fontFamily: themeFonts["Poppins-Regular"],
                                      alignItems: "center",
                                    },
                                  }}
                                  primary={` 1.  Go to your track on Spotify`}
                                />
                              </ListItem>

                              <ListItem
                                sx={{
                                  padding: "0px 0px 16px 0px !important",
                                }}
                              >
                                <ListItemText
                                  sx={{
                                    "& span.MuiListItemText-primary": {
                                      lineHeight: "15px",
                                      display: "flex",
                                      fontSize: "12px",
                                      color: themeColors["#00000080"],
                                      fontFamily: themeFonts["Poppins-Regular"],
                                      alignItems: "center",
                                      gap: "5px",
                                    },
                                  }}
                                >
                                  2. Click the{" "}
                                  <GreenDot
                                    style={{
                                      lineHeight: "15px",
                                      boxSizing: "content-box",
                                      cursor: "pointer",
                                    }}
                                  />
                                  icon
                                </ListItemText>
                              </ListItem>
                              <ListItem
                                sx={{
                                  padding: "0px 0px 16px 0px !important",
                                }}
                              >
                                <ListItemText
                                  sx={{
                                    "& span.MuiListItemText-primary": {
                                      lineHeight: "15px",
                                      display: "flex",
                                      fontSize: "12px",
                                      color: themeColors["#00000080"],
                                      fontFamily: themeFonts["Poppins-Regular"],
                                      alignItems: "center",
                                      gap: "5px",
                                    },
                                  }}
                                >
                                  3. Select{" "}
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      lineHeight: "15px",
                                      color: themeColors["#00ADB5"],
                                      fontFamily: themeFonts["Poppins-Regular"],
                                      cursor: "pointer",
                                    }}
                                  >
                                    Share
                                  </Typography>{" "}
                                  , then{" "}
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      lineHeight: "15px",
                                      color: themeColors["#00ADB5"],
                                      fontFamily: themeFonts["Poppins-Regular"],
                                      cursor: "pointer",
                                    }}
                                  >
                                    copy song link
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                              <ListItem
                                sx={{
                                  padding: "0px 0px 0px 0px !important",
                                }}
                              >
                                <ListItemText
                                  sx={{
                                    "& span.MuiListItemText-primary": {
                                      lineHeight: "15px",
                                      display: "flex",
                                      fontSize: "12px",
                                      color: themeColors["#00000080"],
                                      fontFamily: themeFonts["Poppins-Regular"],
                                      alignItems: "center",
                                    },
                                  }}
                                  primary={` 4. Paste in your link to our submission bar`}
                                />
                              </ListItem>
                            </List>
                          </Box>
                        </Box>
                      </Popover>
                    </>
                  )}
                </Box>
              </Box>
            </>
          );
        })}
      </Box>

      {/* {isOpen && (
          <SongSubmissionDialog
            open={isOpen}
            onClose={handleCloseButton}
            handleSubmit={handleSubmit}
            selectedPlaylists={selectedPlaylists}
          />
        )} */}
    </Grid>
  );
}

export default VisitPublicPage;
